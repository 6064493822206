const getState = (state) => state.batch;
export const getBadFormatLines = (state) =>
  getState(state).get('badFormatLines');
export const getDuplicatedLines = (state) =>
  getState(state).get('duplicatedLines');
export const getBatchLoading = (state) => getState(state).get('isBatchLoading');
export const getPurchaseLoading = (state) =>
  getState(state).get('isPurchaseLoading');
export const getBatchSize = (state) => getState(state).get('batchSize');
export const getCurrentTab = (state) => getState(state).get('currentTab');
export const getRemainingBatch = (state) =>
  getState(state).get('remainingBatch');
export const getReportData = (state) => getState(state).get('reportData');
export const getPurchaseData = (state) => getState(state).get('purchaseData');
export const getFetchReport = (state) =>
  getState(state).get('isFetchingReport');
export const getFetchCredits = (state) =>
  getState(state).get('isFecthingCredits');
export const getFetchPurchases = (state) =>
  getState(state).get('isFetchingPurchases');
export const getZipRequestIds = (state) => getState(state).get('zipRequestIds');
export const getUserCredits = (state) => getState(state).get('userCredits');
export const getPurchaseError = (state) => getState(state).get('purchaseError');
