import React from 'react';
import styled from 'styled-components';
import { theme } from 'styled-tools';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faTimes } from '@fortawesome/free-solid-svg-icons';

const ToastError = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;

  position: absolute;
  right: 0;
  left: 0;
  bottom: 24px;
  margin: 0 auto;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

  background-color: #8d110c;
  color: ${theme('colors.white')};
  border-radius: 16px;
  height: 48px;
  width: 640px;

  @media (max-width: 375px) {
    font-size: 14px;
    padding: 16px;
    width: 300px;
  }
`;

const Icon = styled(FontAwesomeIcon)`
  cursor: pointer;
`;

export default ({ errorMessage, handleClose, size = '3em', ...props }) => {
  return (
    <ToastError>
      {errorMessage}

      <Icon icon={faTimes} size={size} {...props} onClick={handleClose} />
    </ToastError>
  );
};
